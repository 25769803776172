































import Vue from "vue";
import { companiesStore } from "@/service/companies/companies.store";
import TabVesting from "./vesting/TabVesting.vue";
import TabOptions from "./options/TabOptions.vue";
import TabShares from "./shares/TabShares.vue";
import SectionCompanyDetails from "./SectionCompanyDetails.vue";

export default Vue.extend({
  components: {
    TabVesting,
    TabOptions,
    TabShares,
    SectionCompanyDetails,
  },
  data() {
    return {
      company: companiesStore.state.current,
    };
  }
});
