





import Vue, { PropType } from "vue";
import {
  Chart,
  BarController,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { SharesIssuanceAnalysisDto } from "@/service/shares-issuances/shares-issuances.service.dto";

Chart.register(BarController, CategoryScale, LinearScale, BarElement);

export default Vue.extend({
  props: {
    data: {
      required: true,
      type: Array as PropType<Array<SharesIssuanceAnalysisDto>>,
    },
  },
  mounted() {
    new Chart(this.$refs.canvas as HTMLCanvasElement, {
      type: "bar",
      options:{
        scales: {
          x: {
            grid: {
                display: false
            }
          },
          y: {
              grid:{
                display: false
              }
          }
        }
      },
      data: {
        labels: this.data.map( x => this.$d(x.issueDate, "short")),
        datasets: [
          {
            label: this.$t("shares").toString(),
            backgroundColor: this.$vuetify.theme.currentTheme.info?.toString(),
            borderColor: "#222",
            borderWidth: 2,
            borderRadius: 5,
            data: this.data.map( x=> x.accCount),
          },
        ],
      },
    });
  },
});
