






























import Vue from "vue";
import { appStore } from "@/app.store";
import { companiesStore } from "@/service/companies/companies.store";
import ChartOptions from "./ChartOptions.vue";
import TableSharesAnalysis from "./TableSharesAnalysis.vue";
import {
  SharesIssuanceAnalysisDto,
  getSharesIssuancesAnalysisOfStakeholder,
} from "@/service/shares-issuances/shares-issuances.service";

export default Vue.extend({
  components: {
    ChartOptions,
    TableSharesAnalysis,
  },
  data() {
    return {
      loading: false,
      sharePrice: companiesStore.state.current?.sharePrice ?? 0,
      shares: companiesStore.state.current?.shares ?? 0,
      analysis: null as Array<SharesIssuanceAnalysisDto> | null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      if (!appStore.state.user) return;

      try {
        this.loading = true;
        var result = await getSharesIssuancesAnalysisOfStakeholder();
        this.analysis = result;
        this.loading = false;
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
  },
});
