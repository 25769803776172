












import { CompanyDto } from "@/service/companies/companies.service.dto";
import Vue, { PropType } from "vue";

export default Vue.extend({
  props: {
    company: {
      type: Object as PropType<CompanyDto>,
      required: true,
    },
  },
});
