var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-5"},[_c('v-row',[_c('v-col',[_c('Grid',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.vestingEndDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(item.vestingEndDate))+" ")]}},{key:"item.pool",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t("yearsAfterTheVestingDate", { years: Math.floor(item.pool.exerciseDuration / 12) }))+" ")]}},{key:"item.count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.count))+" ")]}},{key:"item.companyPercentage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.companyPercentage))+" % ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }