




























import Vue from "vue";
import { appStore } from "@/app.store";
import { getOptionsIssuancesAnalysisOfStakeholder, OptionsIssuancesAnalysisDto } from "@/service/options-issuances/options-issuances.service";
import Grid from "@/components/grid/Grid.vue";

export default Vue.extend({
  components:{
    Grid
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: this.$t("vestingDate").toString(),
          align: "start",
          sortable: false,
          value: "vestingEndDate",
        },
        {
          text: this.$t("exerciseBy").toString(),
          align: "start",
          sortable: false,
          value: "pool",
        },
        {
          text: this.$t("options").toString(),
          align: "start",
          sortable: false,
          value: "count",
        },
        {
          text: this.$t("percentage").toString(),
          align: "start",
          sortable: false,
          value: "companyPercentage",
        },
      ],
      items: [] as Array<OptionsIssuancesAnalysisDto>,
    };
  },
  async mounted() {
    if (!appStore.state.user) 
      return;

    try {
      this.loading = true;
      var result = await getOptionsIssuancesAnalysisOfStakeholder();
      this.items = result;
      this.loading = false;
    } catch (error) {
      appStore.snackbar(this.$t("shared.genericError").toString(), "error");
    }
  },
});
