





import Vue, { PropType } from "vue";
import {
  Chart,
  BarController,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";

Chart.register(BarController, CategoryScale, LinearScale, BarElement);

interface Data {
  exercised: number;
  vested: number;
  yetToVest: number;
}

export default Vue.extend({
  props: {
    data: {
      required: true,
      type: Object as PropType<Data>,
    },
  },
  mounted() {
    new Chart(this.$refs.canvas as HTMLCanvasElement, {
      type: "bar",
      options:{
        scales: {
          x: {
            grid: {
                display: false
            }
          },
          y: {
              grid:{
                display: false
              }
          }
        }
      },
      data: {
        labels: [this.$t("exercised"), this.$t("vested"), this.$t("yetToVest")],
        datasets: [
          {
            label: this.$t("options").toString(),
            backgroundColor: this.$vuetify.theme.currentTheme.info?.toString(),
            borderColor: "#222",
            borderWidth: 2,
            borderRadius: 5,
            data: [this.data.exercised, this.data.vested, this.data.yetToVest],
          },
        ],
      },
    });
  },
});
