

































import Vue from "vue";
import { appStore } from "@/app.store";
import ChartOptions from "./ChartOptions.vue";
import TableOptionsOverview from "./TableOptionsOverview.vue";
import { companiesStore } from "@/service/companies/companies.store";
import { OptionsIssuancesOverviewDto } from "@/service/options-issuances/options-issuances.service.dto";
import { getOptionsIssuancesOverviewOfStakeholder } from "@/service/options-issuances/options-issuances.service";

export default Vue.extend({
  components: {
    ChartOptions,
    TableOptionsOverview,
  },
  data() {
    return {
      loading: false,
      sharePrice: companiesStore.state.current?.sharePrice ?? 0 ,
      shares: companiesStore.state.current?.shares ?? 0,
      overview: null as OptionsIssuancesOverviewDto | null
    };
  },
  computed: {
    chartData(): any | null {
      if (!this.overview) return null;
      else
        return {
          exercised: this.overview.exercised.count,
          vested: this.overview.vested.count,
          yetToVest: this.overview.yetToVest.count,
        };
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      console.log(appStore.state.user);
       if (!appStore.state.user) 
        return;

      try {
        this.loading = true;
        this.overview = await getOptionsIssuancesOverviewOfStakeholder();
        this.loading = false;
      } catch (error) {
        appStore.snackbar(this.$t("shared.genericError").toString(), "error");
      }
    },
  }
});
