







































































import Vue, { PropType } from "vue";
import { companiesStore } from "@/service/companies/companies.store";
import { SharesIssuanceAnalysisDto } from "@/service/shares-issuances/shares-issuances.service.dto";

export default Vue.extend({
  props: {
    data: {
      required: true,
      type: Array as PropType<Array<SharesIssuanceAnalysisDto>>,
    },
  },
  data() {
    return {
      projectedPrice: "",
    };
  },
  created() {
    this.$watch("localValue", (value) => {
      this.$emit("input", value);
    });
  },
  methods: {
    getProjectedProfit(numberOfShares: number, exercisePrice: number): string {
      var projectedtValue = parseFloat(this.projectedPrice) * numberOfShares;
      var exerciseValue = exercisePrice * numberOfShares;
      return this.projectedPrice
        ? this.$n(projectedtValue - exerciseValue, "currency")
        : "";
    },
    getProjectedProfitAfterTax(
      numberOfShares: number,
      exercisePrice: number
    ): string {
      var tax = (companiesStore.state.current?.taxPercent ?? 0) / 100;

      var exerciseValue = exercisePrice * numberOfShares;
      exerciseValue -= exerciseValue * tax;

      var projectedtValue = parseFloat(this.projectedPrice) * numberOfShares;
      projectedtValue -= projectedtValue * tax;

      return this.projectedPrice
        ? this.$n(projectedtValue - exerciseValue, "currency")
        : "";
    },
  },
});
